body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


html,body,button,input,textarea,div,label {
    margin: 0;
    font-family: 'Open Sans', 'Open Sans Hebrew', Tahoma, Geneva, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

textarea, select, input, button {
    outline: transparent none;
}

div.grid-item {
    min-height: 30px;
}

.pos-top {
    position: -webkit-sticky;
    position: sticky;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    top: 0;
    z-index: 9;
    height: auto;
    padding: 0.5rem 3rem;
    background: #ffffff;
}

.pos-bottom {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: #ffffff;
}

.w-100 {
    width: 100%;
}

.bg-black {
    background: black;
}

.grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 3), 1fr));
    -webkit-transition: 0.1s -webkit-filter linear;
    transition: 0.1s filter linear, 0.1s -webkit-filter linear;
}

.grid-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 1.3em;
    font-weight: bold;
    color: white;
    grid-row: span 1;
    grid-column: span 1;
    background-color: #000000;
}

.grid-item:before {
    content: "";
    height: 0;
    display: inline-block;
    padding-top: 100%;
}

.grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 420px) {
    .grid-container {
        display: grid;
        grid-auto-rows: 50vh;
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
}

@media only screen and (max-width: 768px) and (min-width: 420px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    }
}

@media only screen and (min-width: 768px) {
    .grid-item.span-quad-2 {
        grid-row: span 2;
        grid-column: span 2;
    }

    .grid-item.span-quad-3 {
        grid-row: span 3;
        grid-column: span 3;
    }

    .grid-item.span-quad-4 {
        grid-row: span 4;
        grid-column: span 4;
    }
}

@media only screen and (min-width: 992px) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    }
}

.glide__track, .glide__slides {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.slider .js-lazy-image.glide__slide {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}

.slider .js-lazy-image.glide__slide.glide__slide--active {
    opacity: 1;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #000000;
}

.logo-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    max-width: 15%;
}

.icons {
    max-width: 10%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.icon {
    -webkit-align-self: center;
    align-self: center;
    margin: 0.3rem;
    background: #000;
    border: 1px solid #000;
    border-radius: 50%;
    -webkit-transition: 0.3s -webkit-filter linear;
    transition: 0.3s filter linear, 0.3s -webkit-filter linear;
}

.icon:hover {
     background: transparent;
}
.icon:hover img, .icon:hover source{
    fill: #000000;
    -webkit-filter: invert(100%);
    -moz-filter: invert(100%);
    -o-filter: invert(100%);
    -ms-filter: invert(100%);
    filter: invert(100%);
}

.logo-container img, .icon img {
    display: block;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 900px) and (min-width: 520px) {
    .logo-container {
        max-width: 20%;
    }

    .icons {
        max-width: 20%;
    }
}

@media only screen and (max-width: 520px) {
    .pos-top {
        padding: 0.3rem 0.5rem;
    }

    .pos-bottom {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding: 0.5rem;
    }

    .pos-bottom .icon {
        max-width: 10%;
    }

    .logo-container {
        max-width: 45%;
        text-align: center;
        margin: auto;
    }

    .pos-top .icons {
        display: none;
    }
}

.ReactModal__Content .glide__slides {
    max-height: 80vh;
}

.ReactModal__Content .glide__slide {
    height: auto;
    text-align: center;
}

.glide__slide img {
    max-width: 100%;
    max-height: 100%;
}

.ReactModal__Body--open .grid-container {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
}

.glide__arrow {
    background: #000 !important;
    border: none !important;
    border-radius: 50%!important;
    padding: 4px!important;
    -webkit-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
}
.glide__arrow:hover {
    background: rgba(0,0,0,0.5)!important;
}
.glide__arrow img {
    max-width: 4vw;
    display: block;
}
.glide__arrow--right {
    right: 10vw!important;
}
.glide__arrow--left {
    left: 10vw!important;
}

.row {
    margin: -20px 0;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.row .col {
    padding: 0 20px;
    float: left;
    box-sizing: border-box;
}

.row .col.x-50 {
    width: 50%;
}

.row .col.x-100 {
    width: 100%;
}

.content-wrapper {
    min-height: 100%;
    position: relative;
}

.get-in-touch {
    position: -webkit-sticky;
    position: sticky;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    max-width: 650px;
    max-height: 100vh;
    min-height: 80vh;
    margin: 0 auto;
    overflow: auto;
    letter-spacing: 0.2em;
}

.get-in-touch .title {
    text-align: center;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 36px;
    padding-bottom: 10px;
    letter-spacing: 0.2em;
}

.contact-form .form-field {
    position: relative;
    margin: 32px 0;
}

.contact-form .input-text {
    background: transparent;
    display: block;
    width: 100%;
    height: 36px;
    border-width: 0 0 1px 0;
    border-color: #000;
    font-size: 1rem;
    line-height: 26px;
    font-weight: 400;
    padding-left: 4px;
    letter-spacing: 0.2em;
}

.contact-form .input-text:focus {
    outline: none;
}

.contact-form .input-text:focus + .label, .contact-form .input-text.not-empty + .label {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
}

.contact-form .label {
    font-size: 1rem;
    line-height: 26px;
    font-weight: 400;
    color: #494949;
    cursor: text;
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
    padding-left: 4px;
    letter-spacing: 0.2em;
    opacity: 0.3;
    pointer-events: none;
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    top: 0;
}

.contact-form .submit-btn {
    display: inline-block;
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 24px;
    padding: 8px 16px;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    letter-spacing: 0.2em;
}

.ReactModal__Body--open .close {
    display: inline-block;
}

.close {
    position: fixed;
    display: none;
    width: 25px;
    height: 25px;
    overflow: hidden;
    right: 1%;
    top: 3%;
    z-index: 22;
    cursor: pointer;
}

.close:hover::before, .close:hover::after {
    background: #888888;
}

.close::before, .close::after {
    content: '';
    position: absolute;
    height: 3px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -4px;
    background: #000;
}

.close::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.close::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.close.rounded::before, .close.rounded::after {
    border-radius: 5px;
}

.align-center {
    text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important;
}
.Toastify__toast-body {
    text-transform: none;
    letter-spacing: 0.1em;
}
